'use client';

import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, redirect, useRouter } from 'src/i18n/routing';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {
  getAuth,
  onAuthStateChanged,
  EmailAuthProvider,
  GoogleAuthProvider,
  User,
} from 'firebase/auth';
import { useLocale, useTranslations } from 'next-intl';

import { LoadingWrapper, StyledSpinner } from '../common';
import ky from 'ky';
import { firebaseFunctionsBaseUrl } from '../../initFirebase';
import RichText from '../RichText';
import { useSearchParams } from 'next/navigation';

const Wrapper = styled.div`
  .avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  p {
    line-height: 1.5em;
  }

  hr {
    width: 50%;
    color: #bbb;
    min-width: 200px;
  }
`;
const RetryButton = styled.button`
  display: inline;
  background: none;
  color: #e58a2f;
  border: 0;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
`;
const ContinueButton = styled.button`
  background-color: #fff;
  border: 2px solid #33bb55;
  color: #119933;
  font-weight: bold;
  border-radius: 1em;
  padding: 0.5em 1em;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: #777;
`;

// Gets the i18n key for emailInfo
const getProviderViaNameKey = (user: User) => {
  const userInfos = user.providerData;
  if (userInfos[0]) {
    const provider = userInfos[0].providerId;
    switch (provider) {
      case 'google.com':
        return 'google';
      case 'apple.com':
        return 'apple';
      case 'email':
        return 'email';
    }
  }
  return 'default';
};

function Login() {
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState(true);
  const [ssoAuthError, setSSOAuthError] = useState(false);
  const { push } = useRouter();
  const t = useTranslations();
  const searchParams = useSearchParams();
  const locale = useLocale();

  const isSso = !!searchParams?.has('sso');

  const ssoAuth = useCallback(async () => {
    if (!user) {
      return;
    }

    setLoading(true);
    setSSOAuthError(false);
    let redirectURL;

    try {
      const idToken = await user.getIdToken();

      const response = await ky.post(
        `${firebaseFunctionsBaseUrl}/discourse-session`,
        {
          json: { idToken },
          credentials: 'include',
          mode: 'cors',
        },
      );
      const { session } = (await response.json()) as { session: string };

      const { redirectURL: redirectURLKy } = (await ky
        .get(
          `${firebaseFunctionsBaseUrl}/discourse-sso?${searchParams?.toString() || ''}&session=${encodeURIComponent(session)}`,
          {
            mode: 'cors',
            credentials: 'include',
          },
        )
        .json()) as { redirectURL: string };

      redirectURL = redirectURLKy;
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      setSSOAuthError(true);
      setLoading(false);
    } finally {
      // #51: Redirects cannot happen within try/catch blocks, only the finally block
      if (redirectURL) {
        const redirectURLObj = new URL(redirectURL);
        if (window && redirectURLObj.hostname !== window.location.hostname) {
          redirect({ href: redirectURL, locale });
        }
      }
      setSSOAuthError(true);
      setLoading(false);
      throw new Error('Redirect loop');
    }
  }, [user, locale, searchParams]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (authUser) => {
      setUser(authUser);

      if (authUser && !isSso) {
        push('/');
      } else {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [push, isSso, ssoAuth]);

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
      GoogleAuthProvider.PROVIDER_ID,
      { provider: 'apple.com' },
    ],
    privacyPolicyUrl: 'https://plumvillage.app/privacy/',
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <StyledSpinner />
        <p>{t('common.loading')}</p>
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      {user ? (
        <>
          {isSso ? (
            <>
              {ssoAuthError ? (
                <div>
                  <h1>{t('common.error.oops')}</h1>
                  <p>
                    <RichText>
                      {(tags) =>
                        t.rich('login.error.retryMessage', {
                          ...tags,
                          retry: (chunks) => (
                            <RetryButton onClick={ssoAuth}>
                              {chunks}
                            </RetryButton>
                          ),
                        })
                      }
                    </RichText>
                  </p>
                  <p>
                    <RichText>
                      {(tags) =>
                        t.rich('login.error.contactUsLink', {
                          ...tags,
                          anchor: (chunks) => (
                            <a href="https://plumvillage.app/contact-us/">
                              {chunks}
                            </a>
                          ),
                        })
                      }
                    </RichText>
                  </p>
                </div>
              ) : (
                <>
                  <h2>{t('common.plumVillageApp')}</h2>
                  {user.photoURL && (
                    <p>
                      <img
                        className="avatar"
                        src={user.photoURL}
                        alt={user.displayName || ''}
                      />
                    </p>
                  )}
                  <p>
                    <RichText>
                      {(tags) =>
                        t.rich('common.loginInfo', {
                          ...tags,
                          user: user.displayName || '',
                        })
                      }
                    </RichText>
                    <br />
                    <small>
                      <RichText>
                        {(tags) =>
                          t.rich(
                            `login.emailInfo.${getProviderViaNameKey(user)}`,
                            {
                              ...tags,
                              email: user.email || '',
                            },
                          )
                        }
                      </RichText>
                    </small>
                  </p>
                  <br />
                  <hr />
                  <p>
                    <RichText>
                      {(tags) => t.rich('login.communityForum', tags)}
                    </RichText>
                  </p>
                  <p>
                    <ContinueButton onClick={ssoAuth}>
                      {t('login.continue')}
                    </ContinueButton>
                  </p>
                  <hr />
                  <p>
                    <small>
                      {t('login.error.wrongUser')}{' '}
                      <StyledLink href="/logout">
                        {t('common.logout')}
                      </StyledLink>
                    </small>
                  </p>
                </>
              )}
            </>
          ) : (
            <p>{t('login.successfulRedirectMessage')}</p>
          )}
        </>
      ) : (
        <>
          <h1>{t('login.heading')}</h1>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
          {isSso && (
            <>
              <br />
              <hr />
              <p>
                <RichText>
                  {(tags) => t.rich('login.communityForum', tags)}
                </RichText>
              </p>
              <hr />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}

export default Login;
