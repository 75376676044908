'use client';
// 'use client' is necessary in order for firebase to correctly initialize on the client

import { initializeApp, getApps } from 'firebase/app';
 
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// This must come before any other firebase calls
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

// Although the docs don't seem to specify it for each function, calling the get* functions
// (like getFunctions) with no arguments uses the default app, just like getApp.
// Thus, there's no need for auth, db, etc. exports.
// getApp docs: https://firebase.google.com/docs/reference/js/app.md#getapp_1eaaff4
if (process.env.NODE_ENV !== 'production') {
  const functions = getFunctions();
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const firebaseFunctionsBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://us-central1-plum-village.cloudfunctions.net'
    : 'http://localhost:5001/plum-village/us-central1';
